var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modulos.length > 0)?_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"masterclass",staticClass:"niu-width-track rounded-lg pa-0 ma-0 rounded",style:({
    position: 'relative',
    backgroundColor: '#232635',
    height: (_vm.heightMasterClass + "px"),
    'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
    'niu-light': _vm.infoTrack.isPremium === 0 ? true : false
  }),attrs:{"light":"","elevation":"0"}},[_c('v-row',{staticClass:"pa-0 ma-0 rounded",style:({ height: (_vm.heightMasterClass + "px") })},[_c('v-col',{staticClass:"pa-0 ma-0 rounded",style:({ height: (_vm.imageHeight + "px") }),attrs:{"cols":"12","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('v-img',{staticClass:"rounded-l-lg rounded-r-0 pa-0 ma-0 rounded",style:({
          height: (_vm.imageHeight + "px"),
          borderRadius:
            this.$vuetify.breakpoint.name == 'xs' ? '10px !important' : ''
        }),attrs:{"aspect-ratio":_vm.aspectoImg,"src":require('../../assets/' + _vm.modulo.moduloImg),"lazy-src":require("../../assets/client-loader.gif"),"width":"100%"}},[_c('div',{staticClass:"niu-icono-contenido-premium"},[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.infoTrack.isPremium === 0
                  ? require('../../assets/weblogo_n.png')
                  : require('../../assets/logo_premium_150x150.png')}})],1),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" Contenido "),_c('strong',[_vm._v("Premium")])])],1),_c('div',{staticClass:"niu-icono-contenido-premium-bandera"}),_c('div',{staticClass:"rounded-pill ma-3 pa-1 btn-classmaster d-flex d-sm-none",staticStyle:{"position":"absolute","top":"0","right":"0px"}},[_c('label',{staticClass:"rounded-pill"},[_vm._v(" Clase Maestra ")])]),_c('div',{staticClass:"white--text pa-4 d-flex d-sm-none niu-title-masterclass",attrs:{"id":_vm.infoTrack.trackId}},[_vm._v(" "+_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")]),_c('label',{staticClass:"niu-text niu-text-bold white--text",staticStyle:{"position":"absolute","bottom":"20px","left":"20px","font-size":"2rem"}},[_vm._v(" "+_vm._s(_vm.modulo.moduloEspecialista)+" ")]),(
            (_vm.modulo.isPremium == 1 && _vm.membresia == 2) ||
              (_vm.modulo.isPremium == 0 && _vm.membresia == 1)
          )?_c('v-btn',{staticClass:"niu-play-modulo niu-play-masterclass",attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(_vm.modulo)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.name == 'xs' ? 40 : 80}},[_vm._v(" mdi-play-circle ")])],1):_c('v-btn',{staticClass:"niu-play-modulo niu-play-masterclass",attrs:{"icon":""},on:{"click":_vm.activarModel}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.name == 'xs' ? 40 : 80}},[_vm._v(" mdi-play-circle ")])],1)],1)],1),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-row',{staticClass:"ml-2"},[_c('v-col',[_c('div',{staticClass:"rounded-pill ma-3 pa-1 btn-classmaster d-none d-sm-flex"},[_c('label',{staticClass:"rounded-pill"},[_vm._v(" Clase Maestra ")])]),_c('h3',{staticClass:"white--text d-none d-sm-flex"},[_vm._v(" "+_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")]),_c('div',[_c('span',{staticClass:"ma-0 pa-0 pr-3 white--text font-weight-light niu-text-description d-none d-sm-flex"},[_vm._v(" "+_vm._s(_vm.description)+" ")])])])],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-divider',{staticClass:"d-none d-sm-flex",attrs:{"color":"white"}})],1)],1),(
          _vm.$vuetify.breakpoint.name != 'xs' && _vm.$vuetify.breakpoint.name != 'sm'
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"6","lg":"4","xl":"4"}},[_c('small',{staticClass:"niu-text-bold niu-text white--text pl-3 pr-3 d-none d-sm-flex"},[_c('v-icon',{attrs:{"color":"#ff9801"}},[_vm._v("mdi-clock-time-three-outline")]),_c('p',{staticClass:"caption pl-3 pr-3"},[_vm._v(" "+_vm._s(_vm.modulo.moduloDuracion)+" minutos ")])],1)]),(_vm.track_is_content == 1)?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"6","lg":"4","xl":"4"}},[_c('small',{staticClass:"niu-text-bold niu-text white--text pl-3 pr-3 d-none d-sm-flex"},[_c('v-icon',{attrs:{"color":"#ff9801"}},[_vm._v(" mdi-download-box-outline ")]),_c('label',{staticClass:"caption  pl-3 pr-3"},[_vm._v(" Contenido Descargable ")])],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"6","lg":"4","xl":"4"}},[_c('small',{staticClass:"niu-text-bold niu-text white--text pl-3 pr-3 d-none d-sm-flex"},[_c('v-icon',{attrs:{"color":"#ff9801"}},[_vm._v(" mdi-animation-play-outline ")]),_c('label',{staticClass:"caption  pl-3 pr-3"},[_vm._v(" "+_vm._s(_vm.trackSesiones)+" "),(_vm.trackSesiones > 1)?_c('span',[_vm._v(" Sesiones")]):_c('span',[_vm._v(" Sesión")])])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"","md":"12","justify":"center","align":"center"}},[(
              (_vm.modulo.isPremium == 1 && _vm.membresia == 2) ||
                (_vm.modulo.isPremium == 0 && _vm.membresia == 1)
            )?_c('v-btn',{on:{"click":function($event){return _vm.activeModulo(_vm.modulo)}}},[_c('v-icon',{attrs:{"color":"#fd8f02"}},[_vm._v(" mdi-play ")]),_vm._v(" Ir a Clase Maestra ")],1):_c('v-btn',[_c('v-icon',{attrs:{"color":"#fd8f02"}},[_vm._v(" mdi-play ")]),_c('label',{staticClass:"caption",on:{"click":_vm.activaModelPremium}},[_vm._v(" Ir a Clase Maestra ")])],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }