<template>
  <v-row :class="{ 'overflow-y-hidden': $store.state.dialog }" class="mt-16">
    <v-col>
      <v-row align="center">
        <v-col align="center">
          <MensajeBajada
            :mensaje="
              `Explora es un espacio virtual de descubrimiento y visualización de los diferentes programas y planes de contenido`
            "
          ></MensajeBajada>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-divider color="#4d1552" style="max-width:20%"></v-divider>
                  <label style="color:black;font-size:40px">
                    <strong>
                      <v-icon dark color="#000" class="font-50"
                        >mdi-bookmark-multiple
                      </v-icon>
                      Tracks
                    </strong>
                    que has adquirido
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-for="(loadCarrusel, llave) of loadCarruseles"
            :key="'carrusel2_' + llave"
          >
            <v-col>
              <keep-alive>
                <CarrouselModulos
                  v-if="loadCarrusel.isMasterClass == 0"
                  :infoTrack="{
                    trackTitle: loadCarrusel.trackTitle,
                    trackCode: loadCarrusel.trackCode,
                    trackId: loadCarrusel.trackId,
                    trackAreaconocimientoCode:
                      loadCarrusel.trackAreaconocimientoCode,
                    trackProgreso: loadCarrusel.trackProgreso,
                    isPremium: loadCarrusel.isPremium,
                    modulos: []
                  }"
                  :trackSesiones="loadCarrusel.trackSesiones"
                  :trackDuracion="loadCarrusel.trackDuracion"
                  :trackAddLearning="parseInt(loadCarrusel.track_is_add_learning)"
                ></CarrouselModulos>
                <CarruselMasterClass
                  v-else
                  :infoTrack="{
                    trackTitle: loadCarrusel.trackTitle,
                    trackCode: loadCarrusel.trackCode,
                    trackId: loadCarrusel.trackId,
                    trackAreaconocimientoCode:
                      loadCarrusel.trackAreaconocimientoCode,
                    trackProgreso: loadCarrusel.trackProgreso,
                    isPremium: loadCarrusel.isPremium,
                    
                    modulos: []
                  }"
                  :trackSesiones="loadCarrusel.trackSesiones"
                  :track_id_video="loadCarrusel.track_id_video"
                  :track_is_content="loadCarrusel.track_is_content"
                  :track_description="loadCarrusel.track_description"
                  @OpenDialogModuloPremium="OpenDialogModuloPremium"
                ></CarruselMasterClass>
              </keep-alive>
            </v-col>
          </v-row>
          <agregarRuta></agregarRuta>
          <modalVencimiento
            v-if="dialogVencimiento"
            :dialog="dialogVencimiento"
            @closeDialogVecimiento="closeDialogVecimiento"
          ></modalVencimiento>
          <NiuDialogModuloPremium
            :dialogModuloPremium="dialogModuloPremium"
            @CloseDialogModuloPremium="CloseDialogModuloPremium"
          ></NiuDialogModuloPremium>
        </v-col>
      </v-row>
      <!-- <v-row justify="center" no-gutters>
        <v-col cols="3">
          <ComingSoon />
        </v-col>
        <v-col cols="3">
          <ComingSoon />
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>
<script>
import NiuBanner from '../components/shared/NiuBanner.vue'
import CarrouselNIUFLEEX from '../components/explora/carruselEVA_NIUFLEEX.vue'
import CarrouselExplora from '../components/explora/carruselEVA_ModulosExplora.vue'
import CarrouselModulos from '../components/explora/carruselEVA_Modulos.vue'
// import NiuVideo from '../components/explora/videoMainVimeo.vue'
import NiuDialogVideo from '../components/explora/dialogVideo.vue'
import ComingSoon from '../components/explora/carruselEVA_comingsoon.vue'
import modalVencimiento from '../components/explora/modalVencimiento.vue'
import agregarRuta from '../components/explora/agregarMisRutas.vue'
import MensajeBajada from '../components/explora/mensajeBajada.vue'
import CarruselMasterClass from '../components/explora/carruselMasterClass.vue'
import NiuDialogModuloPremium from '../components/modulo/dialogPremium.vue'
import { mapMutations } from 'vuex'

// import { mapMutations } from 'vuex'
// import Modulo from "./modulo.vue";
export default {
  name: 'Explora',
  components: {
    // Modulo,
    CarrouselNIUFLEEX,
    CarrouselExplora,
    CarrouselModulos,
    // NiuVideo,
    NiuDialogVideo,
    ComingSoon,
    modalVencimiento,
    agregarRuta,
    MensajeBajada,
    CarruselMasterClass,
    NiuDialogModuloPremium,
    NiuBanner
  },
  methods: {
    ...mapMutations(['SET_INFOTRACK']),
    CloseDialogModuloPremium () {
      this.dialogModuloPremium = false
    },
    OpenDialogModuloPremium () {
      // eslint-disable-next-line no-console
      console.log('paso')
      this.dialogModuloPremium = true
    },
    closeDialogVecimiento () {
      this.dialogVencimiento = false
      this.$store.commit('SET_VENCIMIENTOPASO', true)
    },
    resizeBanner () {
      if (document.querySelector('#banner')) {
        let aspecto = 9 / 16

        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            aspecto = 4 / 3
            break
          case 'sm':
            aspecto = 3 / 4
            break
          case 'md':
            aspecto = 13 / 23
            break
          case 'lg':
            aspecto = 13/ 23
            break
          case 'xl':
            aspecto = 13/ 23
            break
        }

        this.altoBannerExplora =
          document.querySelector('#banner').offsetWidth * aspecto

        this.altoVideo =
          (document.querySelector('#banner').offsetWidth * 9) / 16
      }
    }
  },
  computed: {
    
  },
  data: () => ({
    dialogModuloPremium: false,
    player_vimeo: null,
    model: null,
    dialog: false,
    idVideo: 'ZGJizJsSF8A',
    muteYoutube: true,
    exploraCarruseles: [],
    loadCarruseles: [],
    altoBannerExplora: 500,
    altoVideo: 500,
    dialogVencimiento: false,
    isVideo: null
  }),
  async created () {
    // eslint-disable-next-line no-console
    // console.log(this.$route, this.$router)
    this.SET_INFOTRACK({
      trackTitle: '',
      trackCode: '',
      trackId: null,
      trackAreaconocimientoCode: '',
      trackProgreso: null,
      modulos: []
    })

    this.loadCarruseles = (await this.$http.get('/Tracks/TracksNiufleexPayPerView')).data
    // alert(random)

    // eslint-disable-next-line no-console
    console.log(this.loadCarruseles)
  },

  mounted () {
    try {
      this.resizeBanner()

      if (
        this.$storeNiu.getTextOrInt('restantes') < 5 &&
        !this.$store.state.vencimientoPaso
      ) {
        // eslint-disable-next-line no-console
        // console.log(
        //   this.$storeNiu.getTextOrInt('restantes'),
        //   this.$store.state.vencimientoPaso
        // )
        this.dialogVencimiento = true
      }
      // eslint-disable-next-line no-console
      // console.log(this.$storeNiu.getTextOrInt('restantes'))
      document.addEventListener('visibilitychange', function () {
        //eslint-disable-next-line no-console
        console.log(document.visibilityState)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
}
</script>
<style lang="scss" scoped>
.niu-carousel-texto-modulo {
  background-color: rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 1px #000;
  text-align: center;
  bottom: 0;
  height: 60px;
  padding: 4px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

@media (max-width: 600px) {
  .niu-col-height-video {
    height: 200px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .niu-col-height-video {
    height: 340px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .niu-col-height-video {
    height: 530px;
  }
}
@media (min-width: 1264px) and (max-width: 1904px) {
  .niu-col-height-video {
    height: 610px;
  }
}
@media (min-width: 1905px) {
  .niu-col-height-video {
    height: 900px;
  }
}
.v-icon.font-50 {
  font-size: 50px;
}
.v-icon.font-44 {
  font-size: 44px;
}
.niu-text-niufleex {
  color: #4d1552;
}
</style>
