import { render, staticRenderFns } from "./carruselEVA_ModulosExplora.vue?vue&type=template&id=63200ab3&scoped=true&"
import script from "./carruselEVA_ModulosExplora.vue?vue&type=script&lang=js&"
export * from "./carruselEVA_ModulosExplora.vue?vue&type=script&lang=js&"
import style0 from "./carruselEVA_ModulosExplora.vue?vue&type=style&index=0&id=63200ab3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63200ab3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VAvatar,VBtn,VCard,VChip,VCol,VHover,VIcon,VImg,VRow,VScaleTransition,VSheet,VSlideGroup,VSlideItem})
