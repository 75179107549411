var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{class:_vm.iconTrackClass(_vm.trackAreaconocimientoCode),staticStyle:{"display":"inline-block","border-radius":"10px","line-height":"5px","padding":"10px","position":"absolute","top":"0"}},[_c('v-icon',{staticStyle:{"display":"block"},attrs:{"color":"white"}},[_vm._v(" mdi-bookmark-multiple ")]),_c('br'),_c('small',{staticStyle:{"display":"block"}})],1),_c('div',{staticStyle:{"color":"black","font-size":"35px","display":"inline-block","width":"90%","position":"relative","left":"55px","font-weight":"bold"}},[_c('span',{staticClass:"niu-title-explorar",domProps:{"innerHTML":_vm._s(_vm.trackTitle)}}),_c('div',{staticStyle:{"font-size":"1.2rem","font-weight":"normal","color":"#717171"}},[_c('label',[_c('v-icon',{staticStyle:{"color":"#717171"}},[_vm._v("mdi-bookmark-multiple")]),_vm._v(" Módulos: "+_vm._s(_vm.modulos.length)+" ")],1)])])])])],1),_c('h1',{staticClass:"niu-title-carousel"},[_c('v-sheet',{staticClass:"pa-4 transparent",attrs:{"elevation":"0"}},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.areaconocimiento),function(area,index){return _c('v-slide-item',{key:area.code + '_' + index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2 niu-chip",attrs:{"input-value":active,"active-class":"niu-chip-active","depressed":"","rounded":""},on:{"click":function($event){return _vm.selectAreaExplora(toggle, active, area, index)}}},[_c('v-avatar',{attrs:{"left":"","size":"15"}},[_vm._v(" "+_vm._s(area.cantidad)+" ")]),_c('label',{staticStyle:{"color":"white !important"}},[_vm._v(_vm._s(area.title))])],1)]}}],null,true)})}),1)],1)],1),_c('v-sheet',{staticClass:"niu-transparent-explora-modulos niu-position-relative niu-width-track",staticStyle:{"background-color":"#dcdcdc !important","width":"100%"},attrs:{"elevation":"0"}},[_c('v-slide-group',{staticStyle:{"width":"96%","left":"2%"},attrs:{"active-class":"success","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.modulos),function(modulo,index){return _c('v-slide-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.areaMostrada[modulo.areaconocimientoCode]),expression:"areaMostrada[modulo.areaconocimientoCode]"}],key:("modulo_" + (_vm.$router.currentRoute.name) + (modulo.agrupaModuloId) + "_slide" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"niu-card-main niu-background niu-text",class:{
              'niu-dark': modulo.isPremium === 0 ? false : true,
              'niu-light': modulo.isPremium === 0 ? true : false
            }},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{attrs:{"aspect-ratio":17 / 9,"src":require('../../assets/' +
                      (modulo.moduloImg
                        ? modulo.moduloImg
                        : 'proximamente.png')),"lazy-src":require("../../assets/client-loader.gif")}},[_c('div',{staticStyle:{"position":"absolute","top":"45%","right":"45%","z-index":"5"}},[_c('v-btn',{staticClass:"niu-play-modulo",staticStyle:{"text-shadow":"1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc, 0px -1px #ccc"},attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(modulo)}}},[_c('v-icon',{attrs:{"size":"70px"}},[_vm._v(" mdi-play-circle ")])],1)],1),_c('v-row',{staticClass:"fill-height",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-chip',{staticClass:"ma-2 niu-color",class:_vm.colorFondoModuloClass(modulo)},[_vm._v(" Módulo "+_vm._s(modulo.moduloNumber)+" ")])],1)],1)],1)],1),_c('v-scale-transition',[(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-close-circle-outline')}}):_vm._e()],1)],1),_c('v-row',{staticClass:"white--text pa-2 ma-0",attrs:{"align":"end"}},[_c('v-col',[_c('div',{staticStyle:{"position":"absolute","top":"-35px","right":"20px"}},[_c('v-avatar',{staticClass:"niu-color",class:_vm.colorFondoModuloClass(modulo, hover),attrs:{"size":"42"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(modulo.moduloNumber))])])],1),(!modulo.moduloHtml)?_c('span',{staticClass:"niu-carousel-texto-modulo "},[_vm._v(" "+_vm._s(modulo.moduloTitle)+" ")]):_c('span',{staticClass:"niu-carousel-texto-modulo niu-text",class:{
                    'niu-dark': modulo.isPremium === 0 ? false : true,
                    'niu-light': modulo.isPremium === 0 ? true : false
                  },domProps:{"innerHTML":_vm._s(modulo.moduloTitle)}}),_c('p',[_c('v-icon',{staticClass:"niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
                      {'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false})},[_vm._v(" mdi-notebook ")]),_c('label',{staticClass:"niu-text",class:{
                      'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false
                    }},[_vm._v("Pertenece a:")]),_c('br'),_c('label',{staticClass:"niu-text",class:{
                      'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false
                    },staticStyle:{"word-break":"break-word"}},[_c('strong',[_vm._v("Track")]),_vm._v(": "),_c('span',{staticStyle:{"line-height":"18px"}},[_vm._v(" "+_vm._s(modulo.trackTitle)+" ")])])],1),_c('p',{staticStyle:{"height":"50px","padding":"15px 0 0 0"}},[_c('v-icon',{staticClass:"niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
                      {'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false})},[_vm._v(" mdi-account ")]),_c('label',{staticClass:"niu-text mt-5",class:{
                      'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false
                    }},[_c('strong',[_vm._v("Especialista: ")]),_vm._v(" "+_vm._s(modulo.moduloEspecialista))])],1)])],1),_c('div',{staticClass:"grid-containerCarru"},[_c('small',{staticClass:"niu-text niu-text-bold",class:_vm.iconModuloClass(modulo, hover)},[_vm._v("Sesiones: "+_vm._s(modulo.moduloNroSesiones))]),_c('small',{staticClass:"niu-text niu-text-bold",class:_vm.iconModuloClass(modulo, hover)},[_vm._v("Tiempo: "+_vm._s(modulo.moduloDuracion)+" minutos")])]),_c('div',{staticClass:"niu-barra",class:_vm.barraInferiorModuloCarrusel(modulo, hover)})],1)]}}],null,true)})]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }