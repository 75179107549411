<template>
  <div>
    <v-row>
      <v-col>
        <div style="position:relative;">
          <div
            :class="iconTrackClass(trackAreaconocimientoCode)"
            style="display: inline-block;border-radius: 10px;line-height: 5px;padding: 10px;position:absolute;top:0;"
          >
            <v-icon color="white" style="display:block">
              mdi-bookmark-multiple
            </v-icon>
            <br />
            <!--aca saque el track -->
            <small style="display:block; "></small>
          </div>
          <div
            style="color: black; font-size: 35px; display: inline-block; width: 90%; position: relative; left: 55px; font-weight:bold;"
          >
            <span class="niu-title-explorar" v-html="trackTitle"> </span>
            <div style="font-size: 1.2rem; font-weight: normal;color: #717171;">
              <label>
                <v-icon style="color: #717171;">mdi-bookmark-multiple</v-icon>
                Módulos:
                {{ modulos.length }}
              </label>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <h1 class="niu-title-carousel">
      <v-sheet elevation="0" class="pa-4 transparent">
        <v-slide-group multiple show-arrows>
          <v-slide-item
            v-for="(area, index) in areaconocimiento"
            :key="area.code + '_' + index"
            v-slot:default="{ active, toggle }"
          >
            <v-btn
              class="mx-2 niu-chip"
              :input-value="active"
              active-class="niu-chip-active"
              depressed
              rounded
              @click="selectAreaExplora(toggle, active, area, index)"
            >
              <v-avatar left size="15">
                {{ area.cantidad }}
              </v-avatar>
              <label style="color:white !important;">{{ area.title }}</label>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </h1>
    <v-sheet
      class="niu-transparent-explora-modulos niu-position-relative niu-width-track"
      elevation="0"
      style="background-color:#dcdcdc !important;width:100%"
    >
      <v-slide-group
        v-model="model"
        active-class="success"
        show-arrows
        style="width:96%;left:2%;"
      >
        <v-slide-item
          v-for="(modulo, index) in modulos"
          :key="
            `modulo_${$router.currentRoute.name}${modulo.agrupaModuloId}_slide${index}`
          "
          v-slot:default="{ active }"
          v-show="areaMostrada[modulo.areaconocimientoCode]"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="niu-card-main niu-background niu-text"
              :class="{
                'niu-dark': modulo.isPremium === 0 ? false : true,
                'niu-light': modulo.isPremium === 0 ? true : false
              }"
            >
              <v-row class="pa-0 ma-0" align="center" justify="center">
                <v-col class="pa-0 ma-0">
                  <v-img
                    :aspect-ratio="17 / 9"
                    :src="
                      require('../../assets/' +
                        (modulo.moduloImg
                          ? modulo.moduloImg
                          : 'proximamente.png'))
                    "
                    lazy-src="../../assets/client-loader.gif"
                  >
                    <div
                      style="position:absolute; top:45%; right:45%;z-index:5;"
                    >
                      <v-btn
                        icon
                        class="niu-play-modulo"
                        @click="activeModulo(modulo)"
                        style="text-shadow: 1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc, 0px -1px #ccc;"
                      >
                        <v-icon size="70px">
                          mdi-play-circle
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-row class="fill-height" align="end">
                      <v-col cols="4">
                        <v-chip
                          class="ma-2 niu-color"
                          :class="colorFondoModuloClass(modulo)"
                        >
                          Módulo {{ modulo.moduloNumber }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-img>
                </v-col>
                <v-scale-transition>
                  <v-icon
                    v-if="active"
                    color="white"
                    size="48"
                    v-text="'mdi-close-circle-outline'"
                  ></v-icon>
                </v-scale-transition>
              </v-row>
              <v-row align="end" class="white--text pa-2 ma-0">
                <v-col>
                  <div
                    style="position:absolute;
                    top: -35px;
                    right: 20px;"
                  >
                    <v-avatar
                      class="niu-color"
                      :class="colorFondoModuloClass(modulo, hover)"
                      size="42"
                    >
                      <span class="white--text headline">{{
                        modulo.moduloNumber
                      }}</span>
                    </v-avatar>
                  </div>

                  <span
                    v-if="!modulo.moduloHtml"
                    class="niu-carousel-texto-modulo "
                  >
                    {{ modulo.moduloTitle }}
                  </span>
                  <span
                    v-else
                    v-html="modulo.moduloTitle"
                    class="niu-carousel-texto-modulo niu-text"
                    :class="{
                      'niu-dark': modulo.isPremium === 0 ? false : true,
                      'niu-light': modulo.isPremium === 0 ? true : false
                    }"
                  ></span>
                  <p>
                    <v-icon
                      class="niu-text"
                      :class="{
                        ...iconModuloClass(modulo, hover),
                        'niu-dark': modulo.isPremium === 0 ? false : true,
                        'niu-light': modulo.isPremium === 0 ? true : false
                      }"
                    >
                      mdi-notebook
                    </v-icon>
                    <label
                      class="niu-text"
                      :class="{
                        'niu-dark': modulo.isPremium === 0 ? false : true,
                        'niu-light': modulo.isPremium === 0 ? true : false
                      }"
                      >Pertenece a:</label
                    ><br />
                    <label
                      class="niu-text"
                      style="word-break:break-word;"
                      :class="{
                        'niu-dark': modulo.isPremium === 0 ? false : true,
                        'niu-light': modulo.isPremium === 0 ? true : false
                      }"
                    >
                      <strong>Track</strong>:
                      <span style="line-height: 18px;">
                        {{ modulo.trackTitle }}
                      </span>
                    </label>
                  </p>
                  <p style="height:50px;padding: 15px 0 0 0;">
                    <v-icon
                      class="niu-text"
                      :class="{
                        ...iconModuloClass(modulo, hover),
                        'niu-dark': modulo.isPremium === 0 ? false : true,
                        'niu-light': modulo.isPremium === 0 ? true : false
                      }"
                    >
                      mdi-account
                    </v-icon>
                    <label
                      class="niu-text mt-5"
                      :class="{
                        'niu-dark': modulo.isPremium === 0 ? false : true,
                        'niu-light': modulo.isPremium === 0 ? true : false
                      }"
                    >
                      <strong>Especialista: </strong>
                      {{ modulo.moduloEspecialista }}</label
                    >
                  </p>
                  <!--correguir texto-->
                </v-col>
              </v-row>
              <div class="grid-containerCarru">
                <small
                  class="niu-text niu-text-bold"
                  :class="iconModuloClass(modulo, hover)"
                  >Sesiones: {{ modulo.moduloNroSesiones }}</small
                >
                <small
                  class="niu-text niu-text-bold"
                  :class="iconModuloClass(modulo, hover)"
                  >Tiempo: {{ modulo.moduloDuracion }} minutos</small
                >
              </div>
              <div
                class="niu-barra"
                :class="barraInferiorModuloCarrusel(modulo, hover)"
              ></div>
            </v-card>
          </v-hover>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { EventBus } from '../../plugins/event-bus'
export default {
  props: {
    trackTitle: String,
    trackCode: String,
    trackId: Number,
    trackProgreso: Number,
    trackAreaconocimientoCode: {
      type: String,
      defaul: ''
    },
    trackSesiones: {
      type: Number,
      default: 0
    },
    trackDuracion: {
      type: Number,
      default: 0
    },
    isPremium: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['KnowledgeAreas2'])
  },
  async mounted () {
    try {
      if (this.isPremium == 1) {
        let modulos_premium = this.$storeNiu.getTextOrInt('modulos_premium')
        if (
          modulos_premium == null ||
          modulos_premium == {} ||
          new Date().getTime() - modulos_premium.time >= 1800000
        ) {
          this.modulos = (
            await this.$http.get(`/Modulos/TrackExploraPremium`)
          ).data
          this.$storeNiu.set('modulos_premium', {
            time: new Date().getTime(),
            isPremium: this.isPremium,
            modulos: [...this.modulos]
          })
        } else {
          // eslint-disable-next-line no-console
          console.log('cargo store premium')
          this.modulos = modulos_premium.modulos
        }
      } else {
        // this.modulos = (await this.$http.get(`/Modulos/TrackExplora`)).data

        let modulos_pro = this.$storeNiu.getTextOrInt('modulos_pro')
        if (
          modulos_pro == null ||
          modulos_pro == {} ||
          new Date().getTime() - modulos_pro.time >= 1800000
        ) {
          this.modulos = (
            await this.$http.get(`/Modulos/TrackExplora`)
          ).data
          this.$storeNiu.set('modulos_pro', {
            time: new Date().getTime(),
            isPremium: this.isPremium,
            modulos: [...this.modulos]
          })
        } else {
          // eslint-disable-next-line no-console
          console.log('cargo store pro')
          this.modulos = modulos_pro.modulos
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    try {
      if (this.isPremium == 1) {
        this.areaconocimiento = (
          await this.$http(`/AreasConocimientos/areasExploraPremium`)
        ).data
      } else {
        this.areaconocimiento = (
          await this.$http(`/AreasConocimientos/areasExplora`)
        ).data
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },
  watch: {
    searchArea () {
      this.areaMostrada['emprendimientonegocio'] =
        this.searchArea.find(ele => ele == 0) == 0 ? true : false
      this.areaMostrada['liderazgo'] = this.searchArea.find(ele => ele == 1)
        ? true
        : false
      this.areaMostrada['comunicacion'] = this.searchArea.find(ele => ele == 2)
        ? true
        : false
      this.areaMostrada['desarrollopersonal'] = this.searchArea.find(
        ele => ele == 3
      )
        ? true
        : false
      this.areaMostrada['desarrollofisico'] = this.searchArea.find(
        ele => ele == 4
      )
        ? true
        : false
      this.areaMostrada['conocimientotecnico'] = this.searchArea.find(
        ele => ele == 5
      )
        ? true
        : false
    }
  },
  data: () => ({
    model: {},
    modulos: [],
    videos: [],
    areaconocimiento: [],
    searchArea: [0, 1, 2, 3, 4, 5],
    areaMostrada: {
      emprendimientonegocio: true,
      liderazgo: true,
      comunicacion: true,
      desarrollopersonal: true,
      desarrollofisico: true,
      conocimientotecnico: true
    },
    searchFirst: true
  }),
  methods: {
    ...mapMutations('carruseles', ['SET_MODULO']),
    ...mapMutations(['SET_ALERT']),
    selectAreaExplora (toogle, active, area, index) {
      window.toogle = toogle
      window.active = active
      toogle()
      if (this.searchFirst) {
        this.searchArea = []
        this.searchFirst = false
      }

      if (this.searchArea.includes(index)) {
        // if (this.searchArea.length == 1) {
        //   this.searchArea = [0, 1, 2, 3, 4, 5]
        // } else {
        this.searchArea = this.searchArea.filter(ele => ele != index)
        // }
      } else {
        this.searchArea.push(index)
      }

      // eslint-disable-next-line no-console
      // console.log(toogle, active, area, this.searchArea)
    },
    openDialog () {
      EventBus.$emit('SET_createRouteDialog', true)
    },
    activeModulo (moduloSeleccionado) {
      if (moduloSeleccionado.comming_soon == 0) {
        if (this.videos.lenght > 0) {
          document.documentElement.classList.add('niu-overflow-hidden')
          this.$store.commit('dialog', {
            dialog: true,
            modulo: moduloSeleccionado,
            numberVideo: 0
          })
        }
        this.SET_MODULO(moduloSeleccionado)
        this.$router.push({
          name: 'modulo',
          params: { id: moduloSeleccionado.moduloCode, track: 'explora' }
        })
      } else {
        this.SET_ALERT({
          message: 'Contenido aún no disponible',
          type: 'warning',
          snackbar: true
        })
      }
    },
    classModuloCarrusel (modulo, hover) {
      return {
        'niu-rounded-4': true,
        'niu-hover': true,
        'niu-border-emprendimientonegocio-light':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-border-liderazgo-light':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-border-comunicacion-light':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-border-desarrollopersonal-light':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-border-desarrollofisico-light':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-border-conocimientotecnico-light':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    fondoModuloClass (modulo, hover) {
      return {
        'niu-background-gradient-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-gradient-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-gradient-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-gradient-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-gradient-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-gradient-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    iconModuloClass (modulo, hover) {
      return {
        'niu-text-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-text-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-text-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-text-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-text-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-text-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    iconTrackClass (areaconocimientoCode) {
      return {
        'niu-background-solid-emprendimientonegocio':
          areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-solid-liderazgo': areaconocimientoCode == 'liderazgo',
        'niu-background-solid-comunicacion':
          areaconocimientoCode == 'comunicacion',
        'niu-background-solid-desarrollofisico':
          areaconocimientoCode == 'desarrollofisico',
        'niu-background-solid-desarrollopersonal':
          areaconocimientoCode == 'desarrollopersonal',
        'niu-background-solid-conocimientotecnico':
          areaconocimientoCode == 'conocimientotecnico'
      }
    },
    barraInferiorModuloCarrusel (modulo, hover) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    colorFondoModuloClass (modulo, hover) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$fondo: white;
$fondo_card: black;
$color_niufleex: #4d1552;
$fondo_explora: #dcdcdc;
$bg_btn_explora: #d3d3d3;

.niu-dark.niu-background {
  background-color: black !important;
}
.niu-dark.niu-text {
  color: white !important;
}
.niu-light.niu-background {
  background-color: #f5f5f5 !important;
}
.niu-light.niu-text {
  color: black !important;
}

.v-chip--active:hover::before,
.v-chip--active::before {
  opacity: 0 !important;
}
// .v-chip--active {
//   background-color: $color_niufleex;
// }
.v-chip:not(.v-chip--active) {
  // background: $bg_btn_explora !important;
  color: white !important;
}
.v-chip:not(.v-chip--active) .v-avatar {
  background: $bg_btn_explora !important;
}

.niu-color {
  background-color: $color_niufleex !important;
}
.niu-chip {
  background-color: #d3d3d3 !important;
  color: white !important;
}
.niu-chip::before {
  background-color: #d3d3d3 !important;
  color: white !important;
}
.niu-chip .v-avatar {
  background-color: #d3d3d3 !important;
  border: 1px solid white !important;
  padding: 2px;
}
.niu-chip-active {
  background-color: $color_niufleex !important;
}
.niu-chip-active::before {
  opacity: 1 !important;
  background-color: $color_niufleex !important;
}
.niu-chip-active .v-avatar {
  background-color: $color_niufleex !important;
  padding: 2px;
}

.v-chip .v-avatar {
  border: 1px solid #fff;
}
.niu-color.v-chip {
  background-color: $color_niufleex !important;
}

.niu-transparent-explora-modulos {
  background-color: $fondo !important;
}
.niu-numero-modulo {
  position: absolute;
  font-size: 16rem;
  top: 10%;
  left: 85%;
  text-shadow: -3px -3px #000;
  font-weight: bold;
}
.theme--dark.v-btn.v-btn--icon.niu-btn-disabled {
  color: transparent !important;
}
.v-responsive.v-image {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
}
.texto_modulo {
  transform: rotate(-45deg);
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}
.box_vineta {
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  background: linear-gradient(
    -45deg,
    transparent 0,
    transparent 70%,
    rgba(0, 0, 0, 0.75) 0,
    rgba(0, 0, 0, 0.75) 30%
  );
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-containerCarru {
  display: grid;
  grid-template-columns: auto auto;
  position: absolute;
  width: 70%;
  left: 16%;
  bottom: 20px;
}

// $width: ;
// * {
//   position: relative;
// }

.niu-rounded-4 {
  border-radius: 4px;
}
.niu-rounded-tl-4 {
  border-top-left-radius: 4px;
}
.niu-rounded-tr-4 {
  border-top-right-radius: 4px;
}
.niu-rounded-br-4 {
  border-bottom-right-radius: 4px;
}
.niu-rounded-bl-4 {
  border-bottom-left-radius: 4px;
}
.niu-rounded-b-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.niu-rounded-t-4 {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.v-sheet.niu-transparent {
  background-color: transparent;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0),
    0px 8px 10px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0) !important;
}
.niu-title-carousel {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.niu-position-relative {
  position: relative;
}
.niu-overflow-hidden {
  overflow: hidden;
}
.niu-text-carrusel {
  min-height: 100px;
}
.niu-card-main {
  width: 300px;
  height: 270px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  background-color: #f5f5f5 !important;
  border-radius: 16px !important;
}
.niu-width-track {
  width: 100%;
  left: 0%;
  height: 420px;
}
.niu-title-explorar {
  font-size: 1.8rem;
}
@media (max-width: 960px) and (min-width: 600px) {
  .niu-card-main {
    width: 300px;
    height: 270px;
    margin-right: 20px;
    margin-bottom: 15px;
    // padding: 2px;
    // background-color: transparent;
    // border: 1px solid #000;
    border-radius: 16px !important;
  }
  .niu-width-track {
    width: 96%;
    left: 2%;
    height: 420px;
  }
}
@media (max-width: 600px) {
  .niu-card-main {
    width: 300px;
    height: 270px;
    margin-right: 20px;
    margin-bottom: 15px;
    // padding: 2px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 16px !important;
  }
  .niu-width-track {
    width: 98%;
    left: 1%;
    height: 350px;
  }
  .niu-title-explorar {
    font-size: 1.4rem;
  }
}
.niu-carousel-texto-modulo {
  text-align: left;
  /* bottom: 0; */
  /* height: 60px; */
  /* padding: 4px; */
  font-size: 1.1rem;
  font-weight: 700;
  word-break: break-word;
  text-transform: none;
  /* margin-bottom: 40px; */
  display: block;
}
.cicle-ball-area {
  width: 10px;
  height: 10px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0 0;
}
.v-icon.notranslate.mdi.mdi-chevron-left,
.v-icon.notranslate.mdi.mdi-chevron-right {
  font-size: 36px !important;
}
/* .niu-width-track {
  max-width: 100%;
} */
// @media (min-width: 300px) and (max-width: 320px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 280px;
//   }
// }
// @media (min-width: 320px) and (max-width: 340px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 300px;
//   }
// }
// @media (min-width: 340px) and (max-width: 380px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 380px) and (max-width: 400px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 360px;
//   }
// }
// @media (min-width: 400px) and (max-width: 420px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 420px) and (max-width: 440px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 340px;
//   }
// }
// @media (min-width: 440px) and (max-width: 460px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 365px;
//   }
// }
// @media (min-width: 460px) and (max-width: 480px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 385px;
//   }
// }
// @media (min-width: 480px) and (max-width: 500px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 405px;
//   }
// }
// @media (min-width: 500px) and (max-width: 520px) {
//   .niu-card-main {
//     height: 175px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 465px;
//     height: 240px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 520px) and (max-width: 540px) {
//   .niu-card-main {
//     height: 130px;
//     width: 158px;
//   }
//   .niu-width-track {
//     width: 495px;
//     height: 250px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 540px) and (max-width: 560px) {
//   .niu-card-main {
//     height: 199px;
//     width: 224px;
//   }
//   .niu-width-track {
//     width: 465px;
//   }
// }
// @media (min-width: 560px) and (max-width: 580px) {
//   .niu-card-main {
//     height: 199px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 485px;
//   }
// }
// @media (min-width: 580px) and (max-width: 600px) {
//   .niu-card-main {
//     height: 199px;
//     width: 244px;
//   }
//   .niu-width-track {
//     width: 505px;
//   }
// }
// @media (min-width: 600px) and (max-width: 620px) {
//   .niu-card-main {
//     height: 199px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 525px;
//   }
// }
// @media (min-width: 620px) and (max-width: 640px) {
//   .niu-card-main {
//     height: 191px;
//     width: 261px;
//   }
//   .niu-width-track {
//     width: 545px;
//   }
// }
// @media (min-width: 640px) and (max-width: 660px) {
//   .niu-card-main {
//     height: 214px;
//     width: 273px;
//   }
//   .niu-width-track {
//     width: 565px;
//   }
// }
// @media (min-width: 660px) and (max-width: 680px) {
//   .niu-card-main {
//     height: 282px;
//     width: 188px;
//   }
//   .niu-width-track {
//     width: 585px;
//   }
//   .v-slide-group {
//     height: 300px;
//   }
// }
// @media (min-width: 680px) and (max-width: 700px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 605px;
//   }
// }
// @media (min-width: 700px) and (max-width: 720px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 625;
//   }
// }
// @media (min-width: 720px) and (max-width: 740px) {
//   .niu-card-main {
//     height: 157px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 645;
//   }
// }
// @media (min-width: 740px) and (max-width: 760px) {
//   .niu-card-main {
//     height: 165px;
//     width: 212px;
//   }
//   .niu-width-track {
//     width: 665px;
//   }
// }
// @media (min-width: 760px) and (max-width: 780px) {
//   .niu-card-main {
//     height: 171px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 685px;
//   }
// }
// @media (min-width: 780px) and (max-width: 800px) {
//   .niu-card-main {
//     height: 179px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 705px;
//   }
// }
// @media (min-width: 800px) and (max-width: 820px) {
//   .niu-card-main {
//     height: 182px;
//     width: 232px;
//   }
//   .niu-width-track {
//     width: 725px;
//   }
// }
// @media (min-width: 820px) and (max-width: 840px) {
//   .niu-card-main {
//     height: 187px;
//     width: 239px;
//   }
//   .niu-width-track {
//     width: 745px;
//   }
// }
// @media (min-width: 840px) and (max-width: 860px) {
//   .niu-card-main {
//     height: 143px;
//     width: 183px;
//   }
//   .niu-width-track {
//     width: 765px;
//   }
// }
// @media (min-width: 860px) and (max-width: 880px) {
//   .niu-card-main {
//     height: 202px;
//     width: 252px;
//   }
//   .niu-width-track {
//     width: 785px;
//   }
// }
// @media (min-width: 880px) and (max-width: 900px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 805px;
//   }
// }
// @media (min-width: 900px) and (max-width: 920px) {
//   .niu-card-main {
//     height: 206px;
//     width: 266px;
//   }
//   .niu-width-track {
//     width: 825px;
//   }
// }
// @media (min-width: 920px) and (max-width: 940px) {
//   .niu-card-main {
//     height: 156px;
//     width: 202px;
//   }
//   .niu-width-track {
//     width: 845px;
//   }
// }
// @media (min-width: 940px) and (max-width: 960px) {
//   .niu-card-main {
//     height: 167px;
//     width: 209px;
//   }
//   .niu-width-track {
//     width: 865px;
//   }
// }
// @media (min-width: 960px) and (max-width: 980px) {
//   .niu-card-main {
//     height: 169px;
//     width: 213px;
//   }
//   .niu-width-track {
//     width: 885px;
//   }
// }
// @media (min-width: 980px) and (max-width: 1000px) {
//   .niu-card-main {
//     height: 173px;
//     width: 217px;
//   }
//   .niu-width-track {
//     width: 905px;
//   }
// }
// @media (min-width: 1000px) and (max-width: 1020px) {
//   .niu-card-main {
//     height: 170px;
//     width: 218px;
//   }
//   .niu-width-track {
//     width: 910px;
//   }
// }
// @media (min-width: 1020px) and (max-width: 1040px) {
//   .niu-card-main {
//     height: 178px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 945px;
//   }
// }
// @media (min-width: 1040px) and (max-width: 1060px) {
//   .niu-card-main {
//     height: 181px;
//     width: 229px;
//   }
//   .niu-width-track {
//     width: 954px;
//   }
// }
// @media (min-width: 1060px) and (max-width: 1080px) {
//   .niu-card-main {
//     height: 182px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 974px;
//   }
// }
// @media (min-width: 1080px) and (max-width: 1100px) {
//   .niu-card-main {
//     height: 183px;
//     width: 237px;
//   }
//   .niu-width-track {
//     width: 985px;
//   }
// }
// @media (min-width: 1100px) and (max-width: 1120px) {
//   .niu-card-main {
//     height: 187px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1000px;
//   }
// }
// @media (min-width: 1120px) and (max-width: 1140px) {
//   .niu-card-main {
//     height: 190px;
//     width: 246px;
//   }
//   .niu-width-track {
//     width: 1020px;
//   }
// }
// @media (min-width: 1140px) and (max-width: 1160px) {
//   .niu-card-main {
//     height: 194px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1040px;
//   }
// }
// @media (min-width: 1160px) and (max-width: 1180px) {
//   .niu-card-main {
//     height: 159px;
//     width: 203px;
//   }
//   .niu-width-track {
//     width: 1060px;
//   }
// }
// @media (min-width: 1180px) and (max-width: 1200px) {
//   .niu-card-main {
//     height: 205px;
//     width: 262px;
//   }
//   .niu-width-track {
//     width: 1080px;
//   }
// }
// @media (min-width: 1200px) and (max-width: 1220px) {
//   .niu-card-main {
//     height: 209px;
//     width: 267px;
//   }
//   .niu-width-track {
//     width: 1100px;
//   }
// }
// @media (min-width: 1220px) and (max-width: 1240px) {
//   .niu-card-main {
//     height: 210px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1120px;
//   }
// }
// @media (min-width: 1240px) and (max-width: 1260px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1260px) and (max-width: 1280px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1300px) {
//   .niu-card-main {
//     height: 173px;
//     width: 223px;
//   }
//   .niu-width-track {
//     width: 1160px;
//   }
// }
// @media (min-width: 1300px) and (max-width: 1320px) {
//   .niu-card-main {
//     height: 177px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 1180px;
//   }
// }
// @media (min-width: 1320px) and (max-width: 1340px) {
//   .niu-card-main {
//     height: 180px;
//     width: 231px;
//   }
//   .niu-width-track {
//     width: 1200px;
//   }
// }
// @media (min-width: 1340px) and (max-width: 1360px) {
//   .niu-card-main {
//     height: 184px;
//     width: 236px;
//   }
//   .niu-width-track {
//     width: 1220px;
//   }
// }
// @media (min-width: 1360px) and (max-width: 1380px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1380px) and (max-width: 1400px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1400px) and (max-width: 1420px) {
//   .niu-card-main {
//     height: 189px;
//     width: 243px;
//   }
//   .niu-width-track {
//     width: 1260px;
//   }
// }
// @media (min-width: 1420px) and (max-width: 1440px) {
//   .niu-card-main {
//     height: 192px;
//     width: 247px;
//   }
//   .niu-width-track {
//     width: 1280px;
//   }
// }
// @media (min-width: 1440px) and (max-width: 1460px) {
//   .niu-card-main {
//     height: 195px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1300px;
//   }
// }
// @media (min-width: 1460px) and (max-width: 1480px) {
//   .niu-card-main {
//     height: 197px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 1320px;
//   }
// }
// @media (min-width: 1480px) and (max-width: 1500px) {
//   .niu-card-main {
//     height: 201px;
//     width: 259px;
//   }
//   .niu-width-track {
//     width: 1340px;
//   }
// }
// @media (min-width: 1500px) and (max-width: 1520px) {
//   .niu-card-main {
//     height: 205px;
//     width: 263px;
//   }
//   .niu-width-track {
//     width: 1360px;
//   }
// }
// @media (min-width: 1520px) and (max-width: 1540px) {
//   .niu-card-main {
//     height: 230px;
//     width: 290px;
//   }
//   .niu-width-track {
//     width: 1490px;
//     height: 300px;
//   }
// }
// @media (min-width: 1540px) and (max-width: 1560px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1400px;
//   }
// }
// @media (min-width: 1560px) and (max-width: 1580px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1420px;
//   }
// }
// @media (min-width: 1580px) and (max-width: 1600px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1440px;
//   }
// }
// @media (min-width: 1600px) and (max-width: 1620px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1460px;
//   }
// }
// @media (min-width: 1620px) and (max-width: 1640px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1480px;
//   }
// }
// @media (min-width: 1640px) and (max-width: 1660px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1500px;
//   }
// }
// @media (min-width: 1660px) and (max-width: 1680px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1520px;
//   }
// }
// @media (min-width: 1680px) and (max-width: 1700px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1540px;
//   }
// }
// @media (min-width: 1700px) and (max-width: 1720px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1560px;
//   }
// }
// @media (min-width: 1720px) and (max-width: 1740px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1580px;
//   }
// }
// @media (min-width: 1740px) and (max-width: 1760px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1600px;
//   }
// }
// @media (min-width: 1760px) and (max-width: 1780px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1620px;
//   }
// }
// @media (min-width: 1780px) and (max-width: 1800px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1640px;
//   }
// }
// @media (min-width: 1800px) and (max-width: 1820px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1660px;
//   }
// }
// @media (min-width: 1820px) and (max-width: 1840px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1680px;
//   }
// }
// @media (min-width: 1840px) and (max-width: 1860px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1700px;
//   }
// }
// @media (min-width: 1860px) and (max-width: 1880px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1720px;
//   }
// }
// @media (min-width: 1880px) and (max-width: 1900px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1740px;
//   }
// }
// @media (min-width: 1900px) and (max-width: 1920px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1760px;
//   }
// }
// .theme--dark.v-sheet.niu-card-main {
//   background-color: #333333;
//   border-color: #333333;
// }
.niu-hover {
  border-radius: 8px;
  border: 3px solid #444;
  border-radius: 8px;
}
.niu-border-emprendimientonegocio-dark {
  border-color: #ffae04 !important;
}
.niu-border-emprendimientonegocio-light {
  border-color: #ffe800 !important;
}
.niu-border-liderazgo-dark {
  border-color: #f64600 !important;
}
.niu-border-liderazgo-light {
  border-color: #f39d00 !important;
}
.niu-border-desarrollofisico-dark {
  border-color: #770005 !important;
}
.niu-border-desarrollofisico-light {
  border-color: #f40000 !important;
}
.niu-border-desarrollopersonal-dark {
  border-color: #2d6b00 !important;
}
.niu-border-desarrollopersonal-light {
  border-color: #2ae82d !important;
}
.niu-border-conocimientotecnico-dark {
  border-color: #11b29c !important;
}
.niu-border-conocimientotecnico-light {
  border-color: #3effe8 !important;
}
.niu-border-comunicacion-dark {
  border-color: #354CF9 !important;
}
.niu-border-comunicacion-light {
  border-color: #0540f2 !important;
}

.niu-barra {
  width: 70%;
  height: 5px;
  background-color: black;
  position: absolute;
  bottom: 0;
  z-index: 20;
  border-radius: 2px;
  left: 15%;
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 36px;
  border: 1px solid;
  border-radius: 50%;
}
.niu-background-gradient-emprendimientonegocio {
  background: #FFB92E;

}
.niu-background-gradient-liderazgo {
  background: #C10000;
}
.niu-background-gradient-desarrollofisico {
  background: #FC3A72;
}
.niu-background-gradient-desarrollopersonal {
  background: #8AC445;
}
.niu-background-gradient-conocimientotecnico {
  background: #40CCB1;
}
.niu-background-gradient-comunicacion {
  background: #354CF9;
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 36px !important;
  color: #ababab !important;
}

$emprendimientonegocio: #FFB92E;
$liderazgo: #C10000;
$desarrollofisico: #FC3A72;
$desarrollopersonal: #8AC445;
$conocimientotecnico: #40CCB1;
$comunicacion: #354CF9;

.niu-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.niu-text-black {
  color: rgba(0, 0, 0, 1) !important;
}
.niu-text-emprendimientonegocio {
  color: $emprendimientonegocio !important;
}
.niu-text-liderazgo {
  color: $liderazgo !important;
}
.niu-text-desarrollofisico {
  color: $desarrollofisico !important;
}
.niu-text-desarrollopersonal {
  color: $desarrollopersonal !important;
}
.niu-text-conocimientotecnico {
  color: $conocimientotecnico !important;
}
.niu-text-comunicacion {
  color: $comunicacion !important;
}

.niu-color.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.niu-color.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.niu-color.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.niu-color.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.niu-color.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.niu-color.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
.v-application p {
  margin-bottom: 4px !important;
}
.niu-play-modulo {
  color: white !important;
}
.niu-play-modulo:hover {
  color: #ddd !important;
}
</style>
