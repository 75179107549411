var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-16",class:{ 'overflow-y-hidden': _vm.$store.state.dialog }},[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('MensajeBajada',{attrs:{"mensaje":"Explora es un espacio virtual de descubrimiento y visualización de los diferentes programas y planes de contenido"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-divider',{staticStyle:{"max-width":"20%"},attrs:{"color":"#4d1552"}}),_c('label',{staticStyle:{"color":"black","font-size":"40px"}},[_c('strong',[_c('v-icon',{staticClass:"font-50",attrs:{"dark":"","color":"#000"}},[_vm._v("mdi-bookmark-multiple ")]),_vm._v(" Tracks ")],1),_vm._v(" que has adquirido ")])],1)],1)],1)],1),_vm._l((_vm.loadCarruseles),function(loadCarrusel,llave){return _c('v-row',{key:'carrusel2_' + llave},[_c('v-col',[_c('keep-alive',[(loadCarrusel.isMasterClass == 0)?_c('CarrouselModulos',{attrs:{"infoTrack":{
                  trackTitle: loadCarrusel.trackTitle,
                  trackCode: loadCarrusel.trackCode,
                  trackId: loadCarrusel.trackId,
                  trackAreaconocimientoCode:
                    loadCarrusel.trackAreaconocimientoCode,
                  trackProgreso: loadCarrusel.trackProgreso,
                  isPremium: loadCarrusel.isPremium,
                  modulos: []
                },"trackSesiones":loadCarrusel.trackSesiones,"trackDuracion":loadCarrusel.trackDuracion,"trackAddLearning":parseInt(loadCarrusel.track_is_add_learning)}}):_c('CarruselMasterClass',{attrs:{"infoTrack":{
                  trackTitle: loadCarrusel.trackTitle,
                  trackCode: loadCarrusel.trackCode,
                  trackId: loadCarrusel.trackId,
                  trackAreaconocimientoCode:
                    loadCarrusel.trackAreaconocimientoCode,
                  trackProgreso: loadCarrusel.trackProgreso,
                  isPremium: loadCarrusel.isPremium,
                  
                  modulos: []
                },"trackSesiones":loadCarrusel.trackSesiones,"track_id_video":loadCarrusel.track_id_video,"track_is_content":loadCarrusel.track_is_content,"track_description":loadCarrusel.track_description},on:{"OpenDialogModuloPremium":_vm.OpenDialogModuloPremium}})],1)],1)],1)}),_c('agregarRuta'),(_vm.dialogVencimiento)?_c('modalVencimiento',{attrs:{"dialog":_vm.dialogVencimiento},on:{"closeDialogVecimiento":_vm.closeDialogVecimiento}}):_vm._e(),_c('NiuDialogModuloPremium',{attrs:{"dialogModuloPremium":_vm.dialogModuloPremium},on:{"CloseDialogModuloPremium":_vm.CloseDialogModuloPremium}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }